<template>
  <div class="wrapper">
    <admin-title title="客服设置"></admin-title>
    <el-form ref="form" :model="formData" label-width="120px">
      <el-form-item label="客服电话：">
        <el-input style="width: 400px" v-model="formData.config_data.mobile" size="small" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="">
        <el-button type="" size="small" @click="getSiteDetail">取 消</el-button>
        <el-button type="primary" size="small" @click="save">保 存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getDetailAPI, settingAPI } from './api'

export default {
  name: 'Site',

  data() {
    return {
      formData: {
        key: 'kefu',
        config_data: {
          mobile: ''
        }
      }
    }
  },

  mounted() {
    this.getSiteDetail()
  },

  methods: {
    async getSiteDetail() {
      const res = await getDetailAPI('kefu')
      if (!res) {
        this.formData = {
          key: 'kefu',
          config_data: {
            mobile: ''
          }
        }
      } else {
        this.formData = res
      }
    },
    save() {
      settingAPI(this.formData).then(() => {
        this.$message.success('保存成功')
        this.getSiteDetail()
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
